import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
	name: 'safeHtmlSanitizer'
})
export class SafeHtmlSanitizerPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {
	}

	transform(html): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}
