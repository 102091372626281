import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class DialogService {

	constructor(private dialog: MatDialog) {
	}

	openDialog(component: ComponentType<any>, config?: MatDialogConfig): MatDialogRef<any> {
		return this.dialog.open(component, config);
	}

	openConfirmationDialog(message: string, options?: { leftButtonText?: string, rightButtonText?: string, hideLeftButton?: boolean, hideRightButton?: boolean, disableClose?: boolean, invertButtonColors?: boolean }): MatDialogRef<ConfirmationDialogComponent> {
		const config: MatDialogConfig = {
			role: 'alertdialog',
			panelClass: 'confirmation-dialog',
			disableClose: options?.disableClose
		};
		const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.openDialog(ConfirmationDialogComponent, config);
		dialogRef.componentInstance.message = message;
		dialogRef.componentInstance.onLeftButtonClick = () => dialogRef.close();
		dialogRef.componentInstance.onRightButtonClick = () => dialogRef.close();
		dialogRef.componentInstance.hideLeftButton = options?.hideLeftButton;
		dialogRef.componentInstance.hideRightButton = options?.hideRightButton;
		dialogRef.componentInstance.invertButtonColors = options?.invertButtonColors;
		if (options) {
			if (options.leftButtonText) {
				dialogRef.componentInstance.leftButtonText = options.leftButtonText;
			}
			if (options.rightButtonText) {
				dialogRef.componentInstance.rightButtonText = options.rightButtonText;
			}
		}
		return dialogRef;
	}

	closeAllDialogs(): void {
		this.dialog.closeAll();
	}

}
