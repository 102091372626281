<img class="icon" alt="confirmation type icon" [src]="iconUri" [class.warning]="isWarning">
<h3 class="message" [innerHTML]="message"></h3>
<div *ngIf="innerHtml" [innerHTML]="innerHtml | safeHtmlSanitizer"></div>
<div class="buttons">
	<aga-button *ngIf="!hideLeftButton"
	            id="left-confirmation-button"
	            class="left-confirmation-button"
	            [color]="invertButtonColors ? 'primary' : 'secondary'"
	            (click)="onLeftButtonClick()">{{leftButtonText}}</aga-button>
	<aga-button *ngIf="!hideRightButton"
	            id="right-confirmation-button"
	            class="right-confirmation-button"
	            [color]="invertButtonColors ? 'secondary': 'primary'"
	            (click)="onRightButtonClick()">{{rightButtonText}}</aga-button>
</div>
