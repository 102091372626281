import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'aga-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
	iconUri: string = '/assets/icons/svg/ic_Warning-Info_24x24.svg';
	isWarning: boolean;
	message: string;
	innerHtml: string;
	leftButtonText: string;
	rightButtonText: string;
	hideRightButton: boolean;
	hideLeftButton: boolean;
	invertButtonColors: boolean;
	onLeftButtonClick: Function;
	onRightButtonClick: Function;

	constructor(private translateService: TranslateService) {
	}

	ngOnInit(): void {
		if (!this.leftButtonText) {
			this.leftButtonText = this.translateService.instant('genericButtons.no');
		}
		if (!this.rightButtonText) {
			this.rightButtonText = this.translateService.instant('genericButtons.yes');
		}
		this.isWarning = this.iconUri.includes('Warning-Question');
	}
}
